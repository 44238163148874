import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Grid, MenuItem, CircularProgress, Paper, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../../Logo/logo.png';

const Payment = () => {
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const [users, setUsers] = useState('');
  const [sdkLoaded, setSdkLoaded] = useState(false);
  const navigate = useNavigate();

  const showToast = (message) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const fetchUser = useCallback(async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getUserById?userId=${userId}`, config);
      const userData = response.data.data;
      setUsers(userData);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  }, [token, userId, setUsers]);

  useEffect(() => {
    fetchUser();
    const loadRazorpaySdk = async () => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => setSdkLoaded(true);
      document.body.appendChild(script);
    };

    loadRazorpaySdk();

    return () => {
      const scriptToRemove = document.querySelector('script[src="https://checkout.razorpay.com/v1/checkout.js"]');
      if (scriptToRemove) {
        document.body.removeChild(scriptToRemove);
      }
    };
  }, [fetchUser]);

  const fetchData = async () => {
    if (!amount || !currency) {
      setErrors({
        amount: !amount ? 'Please enter an amount' : '',
        currency: !currency ? 'Please select a currency' : '',
      });
      showToast('Please enter an amount');
      return;
    }

    setLoading(true);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-order`,
        {
          amount: amount * 100,
          currency: 'USD',
        },
        config
      );
      const options = {
        key: 'rzp_live_KUdsUyW8RNcCT9',
        amount: response.data.data.amount,
        currency: response.data.data.currency,
        order_id: response.data.data.id,
        image: logo,
        name: 'WATCH NOD',
        description: 'Payment for services',
        handler: (response) => { // Use arrow function to match the shorthand requirement
          if (response.razorpay_payment_id) {
            handlePaymentSuccess(response.razorpay_payment_id);
          } else {
            console.log('Payment failed or was cancelled');
          }
        },
      };
      if (sdkLoaded) {
        const razorpay = new window.Razorpay(options);
        razorpay.open();
      }
    } catch (error) {
      console.error('Error creating order:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentSuccess = async (razorpayPaymentId) => {
    await addBalanceToWallet(razorpayPaymentId);
    setErrors({});
  };

  const addBalanceToWallet = async (razorpayPaymentId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const requestData = {
        id: users._id,
        paymentId: razorpayPaymentId,
        amount,
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/addBalanceToUser`, requestData, config);

      if (response.data.success === true) {
        navigate('/dashboard/wallet/success', { state: { showSuccess: true } });
        handleSuccess('Balance added successfully');
      } else {
        console.error('Error adding balance. Unexpected error:', response.data);
      }
    } catch (error) {
      console.error('Error adding balance:', error);
    }
  };

  const handleSuccess = (successMessage) => {
    toast.success(successMessage);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, amount: '' }));
  };

  return (
    <>
      <Paper elevation={1} style={{ padding: '2rem', backgroundColor: '#f2f2f2' }}>
        <Typography variant="h4" style={{ textAlign: 'center', paddingBottom: '15px' }}>
          Add Balance
        </Typography>
        <Grid container spacing={2} alignItems="center" justify="center">
          <Grid item xs={12}>
            <TextField
              label="Amount"
              variant="outlined"
              type="number"
              value={amount}
              onChange={handleAmountChange}
              required
              error={Boolean(errors.amount)}
              fullWidth
              InputLabelProps={{ style: { color: '#333' } }}
              InputProps={{ style: { backgroundColor: '#ffffff' } }}
            />
            {errors.amount && (
              <Typography variant="caption" color="error">
                {errors.amount}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              label="Currency"
              variant="outlined"
              value={currency}
              onChange={(event) => setCurrency(event.target.value)}
              required
              error={Boolean(errors.currency)}
              style={{ width: '100%' }}
              InputLabelProps={{ style: { color: '#333' } }}
            >
              <MenuItem value="USD">USD</MenuItem>
            </TextField>
            {errors.currency && (
              <Typography variant="caption" color="error">
                {errors.currency}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
              <Button variant="contained" color="primary" onClick={fetchData} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Add Balance'}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Payment;
