import React from 'react';
import { Box, darken, Divider, Stack, styled, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PolicyIcon from '@mui/icons-material/Policy';
import { FaPhoneAlt } from 'react-icons/fa';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { CancelOutlined, MonetizationOnOutlined } from '@mui/icons-material';
import Link from '@mui/material/Link';
import FooterTitle from './FooterTitle';
import FooterLink from './FooterLink';
import { Colors } from '../style/theme';

const CombinedLink = () => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <TaskAltIcon style={{ marginLeft: '5px' }} />
    Delivery
    <CancelOutlined style={{ marginLeft: '5px' }} />
    Cancellation &
    <MonetizationOnOutlined style={{ marginLeft: '5px' }} />
    Refund
  </div>
);

const Footer = () => {
  const StackColumn = styled(Stack)(() => ({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    gap: 8,
    textAlign: 'center',
  }));

  const BoxRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.shaft,
    color: Colors.black,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 30,
    },
  }));

  return (
    <BoxRow
      component="footer"
      sx={{
        py: 8,
        px: 2,
      }}
    >
      <StackColumn>
        <FooterTitle text={'contact'} />
        <FooterLink text={'info@watchnod.com'} link="" icon={<MailOutlineIcon />} />
        <Typography variant="body2">Tylect Technologies, Manasa, Neemuch, 458110</Typography>
        <FooterLink text={'+91-9024862242'} link="about#contact-us" icon={<FaPhoneAlt />} />
      </StackColumn>
      <Divider orientation="vertical" flexItem />
      <StackColumn>
        <FooterTitle text={'Help'} />
        <FooterLink text={'terms of service'} link={'termsAndConditions'} icon={<HelpOutlineIcon />} />
        <FooterLink text={'customer support'} link={'about#contact-us'} icon={<QuestionAnswerIcon />} />
        <FooterLink text={'Privacy Policy'} link={'privacyPolicy'} icon={<PolicyIcon />} />
        <FooterLink text={<CombinedLink />} link={'serviceDeliveryPolicy'} />
      </StackColumn>
      <Divider orientation="vertical" flexItem />
      <StackColumn>
        <FooterTitle text={'About'} />
        <FooterLink text={'about us'} link={'about'} icon={<InfoOutlinedIcon />} />
        {/* Add icons for other links if needed */}
        <FooterLink text={'FAQ'} link={'faq'} icon={<QuestionAnswerIcon />} />
      </StackColumn>
      <Divider orientation="vertical" flexItem />
      <StackColumn>
        <FooterTitle text={'WatchNod'} />
        <Stack direction="row" width="70px" maxWidth="100%" justifyContent="space-between">
          <Link
            href="https://www.instagram.com/tylect_technologies"
            variant="body2"
            sx={{
              color: '#E4405F',
              '&:hover': {
                color: darken('#E4405F', 0.4), 
              },
            }}
          >
            <InstagramIcon />
          </Link>

          <Link
            href="https://www.linkedin.com/company/tylect/"
            variant="body2"
            sx={{
              color: '#0A66C2',
              '&:hover': {
                color: darken('#0A66C2', 0.4), 
              },
            }}
          >
            <LinkedInIcon />
          </Link>
        </Stack>
        <Typography variant="caption" component="p">
          &copy; 2024 WatchNod as <b> Tylect Technologies</b>.
        </Typography>
      </StackColumn>
    </BoxRow>
  );
};

export default Footer;
