import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, CardContent, Typography, Avatar, Grid, Divider, Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSpring, animated } from 'react-spring';
import './Testimonials.css';
import { Footer } from '../Footer';
import { NavbarPage } from '../Navbar/Navbar';

const StyledCard = styled(Card)`
  background-color: #f0f8ff;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: justify;
`;
const TopMargin = styled.div`
  margin-top: 100px;
`;

const TestimonialCard = styled(Card)`
  && {
    margin: 20px;
    text-align: center;
    padding: 20px;
  }
`;

const TestimonialContent = styled(CardContent)`
  && {
    padding: 16px;
  }
`;

const TestimonialAvatar = styled(Avatar)`
  && {
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
  }
`;

const TestimonialText = styled(Typography)`
  && {
    margin-bottom: 12px;
  }
`;
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  centerMode: true,
  centerPadding: '0',
};

const testimonialsData = [
  {
    testimonial:
      'We’ve been using this monitoring solution for several months, and it has far exceeded our expectations. The real-time insights, paired with proactive alerts, have been instrumental in identifying potential issues before they impact our services.',
  },
  {
    testimonial:
      'This solution’s monitoring tools are outstanding. They provide an in-depth overview of our system’s health, helping us quickly pinpoint and resolve issues. As a result, we’ve seen significant improvements in the reliability of our infrastructure.',
  },
  {
    testimonial:
      'Our team has gained tremendous value from this monitoring solution. The intuitive interface and customizable dashboards allow us to create a tailored experience, and the ability to dive deep into specific metrics has optimized our resource allocation.',
  },
  {
    testimonial:
      'Adopting this monitoring solution has been transformative for our organization. The automated reporting and trend analysis capabilities have empowered us to make more informed, data-driven decisions, leading to higher operational efficiency and less downtime.',
  },
  {
    testimonial:
      'This monitoring solution has become indispensable for our IT team. Its seamless integration with our existing infrastructure and the in-depth analytics have significantly simplified the management of our dynamic environment.',
  },
];

function TestimonialPage() {
  const testimonials = [
    'The dashboard is incredibly intuitive and easy to navigate – a true standout feature!',
    'Real-time alerts have been a lifesaver, helping us avoid potential downtime and service interruptions.',
    'A highly scalable solution that grows with our business – perfect for expanding teams and infrastructure.',
    'Top-tier security features that ensure peace of mind and protect our critical systems.',
    'The detailed metrics provide actionable insights that drive better decision-making across teams.',
    'Seamless integration with our existing infrastructure – no disruptions, just results.',
    'Customer support is exceptional, always responsive and ready to assist with any questions or issues.',
    'An incredibly cost-effective solution that offers premium features without the premium price tag.',
    'Customizable settings that perfectly align with our unique business needs and workflows.',
    "By far the best server monitoring tool we've used – incredibly reliable and efficient.",
    'The user-friendly interface ensures a minimal learning curve, even for new team members.',
    'Accurate and reliable uptime statistics that are crucial for our operations and planning.',
    'Advanced analytics that help optimize server performance and prevent potential bottlenecks.',
    'Transparent pricing with no hidden fees – what you see is what you get.',
    'Efficient resource allocation has improved our operational efficiency and cost management.',
    'An intelligent alert system that proactively solves problems before they become issues.',
    'Highly recommended for IT professionals who need a robust, reliable monitoring solution.',
    'Comprehensive reporting tools that make audits easy and insightful.',
    'Cloud-ready and future-proof, ensuring our infrastructure is prepared for tomorrow’s needs.',
    'A game-changer in server monitoring – it has far exceeded our expectations and enhanced our operations.',
  ];

  const springProps = useSpring({ opacity: 1, from: { opacity: 0 } });

  const AnimatedTestimonialCard = animated(TestimonialCard);

  return (
    <>
      <Helmet>
        <title> Testimonial | WatchNod </title>
      </Helmet>
      <NavbarPage />
      <TopMargin />
      <CssBaseline />
      <StyledCard>
        <main>
          <Container>
            <Card>
              <div className="testimonials-container">
                <h2 style={{ textAlign: 'center', marginBottom: '30px', color: '#333333' }}>
                  &lsquo; What our customers say &rsquo;
                </h2>
                <div className="slider-container">
                  <Slider {...settings}>
                    {testimonialsData.map((testimonial, index) => (
                      <div key={index} className="testimonial-item">
                        <TestimonialAvatar alt="User" src={`/assets/images/avatars/gender/avatar_${index + 20}.jpg`} />
                        <p>{testimonial.testimonial}</p>
                        <p className="testimonial-name">{testimonial.name}</p>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </Card>
          </Container>
          <br />
          <Divider />
          <Grid container justifyContent="center">
            {testimonials.map((testimonial, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                {/* Pass the style prop with the springProps */}
                <AnimatedTestimonialCard style={springProps}>
                  <TestimonialAvatar alt="User" src={`/assets/images/avatars/gender/avatar_${index + 1}.jpg`} />
                  <TestimonialContent>
                    <TestimonialText variant="body1" color="textPrimary">
                      "{testimonial}"
                    </TestimonialText>
                  </TestimonialContent>
                </AnimatedTestimonialCard>
              </Grid>
            ))}
          </Grid>
        </main>
      </StyledCard>
      <div style={{ position: 'relative', bottom: 0, width: '100%' }}>
        <Footer />
      </div>
    </>
  );
}

export default TestimonialPage;
