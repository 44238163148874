// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Wallet.css */
.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; 
}

.filter-container button {
  background-color: #007bff; 
  color: #fff; 
  border: none;
  cursor: pointer;
}

.total-balance {
  font-weight: bold;
  text-align: right;
}

.total-balance-text {
  font-size: 24px;
}

.balance-cell {
  font-weight: bold;
}

.green-text {
  color: green; 
}

.red-text {
  color: red; 
}

.standard-table {
  width: 100%;
  margin-bottom: 20px; 
}

.standard-table th {
  background-color: #f2f2f2; 
}

.standard-table tbody tr:hover {
  background-color: #e0e0e0; 
}

.transaction-type-header {
  color: #007bff;
  font-weight: bold; 
}
`, "",{"version":3,"sources":["webpack://./src/sections/@dashboard/wallet/Wallet.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":["/* Wallet.css */\n.filter-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px; \n}\n\n.filter-container button {\n  background-color: #007bff; \n  color: #fff; \n  border: none;\n  cursor: pointer;\n}\n\n.total-balance {\n  font-weight: bold;\n  text-align: right;\n}\n\n.total-balance-text {\n  font-size: 24px;\n}\n\n.balance-cell {\n  font-weight: bold;\n}\n\n.green-text {\n  color: green; \n}\n\n.red-text {\n  color: red; \n}\n\n.standard-table {\n  width: 100%;\n  margin-bottom: 20px; \n}\n\n.standard-table th {\n  background-color: #f2f2f2; \n}\n\n.standard-table tbody tr:hover {\n  background-color: #e0e0e0; \n}\n\n.transaction-type-header {\n  color: #007bff;\n  font-weight: bold; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
