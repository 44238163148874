import React from 'react';
import { Container, Divider } from '@mui/material';
import styled from 'styled-components';
import a1 from '../../Assets/comprehensing-monitoring.webp';
import a2 from '../../Assets/user-friendly-dashboard.webp';
import a3 from '../../Assets/customize-sol.jpg';
import a4 from '../../Assets/security-first.jpg';

const TeamItem = styled.div`
  background-color: #f0f8ff;
  text-align: justify;
  margin: 20px;
  padding: 50px 30px 50px 30px;
  border-radius: 8px;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #3956de;
    color: white;
  }

  h3 {
    margin: 20px 0 1px 0 !important;
    color: #454545;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    transition: all 0.25s ease-in-out;
  }

  &:hover h3 {
    color: #ddd;
  }
`;

const SectionWhite = styled.section`
  background: #fff;
  padding: 0;
`;

const TeamInfo = styled.div`
  display: block;
  margin-bottom: 0;

  p {
    color: #acacac;
    font-style: italic;
  }

  &::after {
    background: linear-gradient(135deg, #6b02ff 0%, #985bef 100%);
    display: inline-block;
    vertical-align: middle;
    content: '';
    width: 50px;
    height: 3px;
  }
`;

const TeamImg = styled.img`
  max-width: 300px;
  padding: 6px 6px;
  background-color: #dedede;
  border-radius: 5%;
  display: block;
  margin: auto;
`;

const TeamRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const TeamInfoContainer = styled.div`
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function AboutUs() {
  const message = `Your trusted partner in server monitoring and analytics. At the forefront of innovation, we are committed to empowering businesses with reliable, efficient, and cutting-edge solutions to ensure optimal server performance. With a strong foundation built on trust, excellence, and continuous improvement, we provide the tools you need to proactively manage and maintain seamless server operations.`;
  return (
    <>
      <SectionWhite>
        <Container>
          <div className="row">
            <TeamInfoContainer className="col-md-12 mx-auto">
              <h2 className="section-title">Who We Are</h2>
              <p className="section-subtitle">
                <span>'Welcome to Watch-Nod' –</span>
                {message}
              </p>
            </TeamInfoContainer>
            <TeamRow>
              <TeamItem>
                <TeamImg
                  src={a1}
                  className="team-img"
                  alt="Comprehensive Monitoring"
                  width="300px"
                  height="auto"
                  loading="lazy"
                  title="Monitoring"
                />
                <h3>COMPREHENSIVE MONITORING</h3>
                <TeamInfo>{/* <p>Head of SEO</p> */}</TeamInfo>
                <p>
                  Gain complete visibility into your server's health with detailed insights into uptime, latency, and
                  more. Our advanced algorithms work around the clock, continuously analyzing your infrastructure to
                  provide real-time updates and proactive alerts. Stay ahead of potential issues and ensure your systems
                  are always running at their best.
                </p>
              </TeamItem>

              <TeamItem>
                <TeamImg
                  src={a2}
                  className="team-img"
                  width="300px"
                  alt="User-Friendly Dashboard"
                  height="auto"
                  loading="lazy"
                  title="User-Friendly Dashboard"
                />
                <h3>INTUITIVE DASHBOARD</h3>
                <TeamInfo>{/* <p>Marketing Manager</p> */}</TeamInfo>
                <p>
                  We prioritize ease of use. Our dashboard is crafted to provide a smooth, intuitive experience,
                  allowing you to effortlessly monitor multiple servers, visualize key data, and generate comprehensive
                  reports—all in one place. With a focus on simplicity, it ensures that you can manage your server
                  infrastructure with ease and efficiency.
                </p>
              </TeamItem>

              <TeamItem>
                <TeamImg
                  src={a3}
                  className="team-img"
                  width="300px"
                  alt="Customizable Solutions"
                  height="auto"
                  loading="lazy"
                  title="Customizable Solutions"
                />
                <h3>CUSTOMIZABLE SOLUTIONS</h3>
                <TeamInfo>{/* <p>SEO Specialist</p> */}</TeamInfo>
                <p>
                  Understanding that every business has its own unique requirements, we offer flexible monitoring
                  solutions that can be customized to fit your specific needs. Whether you're a small to medium-sized
                  business or a large enterprise, our platform is designed to scale with your growth, providing the
                  right tools to ensure seamless server management at every stage.
                </p>
              </TeamItem>

              <TeamItem>
                <TeamImg
                  src={a4}
                  className="team-img"
                  width="300px"
                  alt="Security First"
                  height="auto"
                  loading="lazy"
                  title="Security First"
                />
                <h3>PRIORITIZING SECURITY</h3>
                <TeamInfo>{/* <p>SEO Specialist</p> */}</TeamInfo>
                <p>
                  In today’s rapidly evolving cybersecurity landscape, safeguarding your servers is our top priority.
                  With cutting-edge encryption protocols and continuous, robust monitoring, we ensure that your data
                  remains protected at all times. Trust us to provide the highest level of security, so you can focus on
                  what matters most—growing your business with peace of mind.
                </p>
              </TeamItem>
            </TeamRow>
          </div>
        </Container>
      </SectionWhite>
      <Divider />
      <SectionWhite>
        <Container>
          <div className="row">
            <TeamRow>
              <TeamItem>
                <h2 className="section-title">MEET OUR TEAM</h2>
                <p className="section-subtitle">
                  At the heart of every breakthrough, every innovative feature, and every satisfied customer is a team
                  of passionate professionals dedicated to excellence. Our engineers, data scientists, and support staff
                  work relentlessly to keep ResourceDekho at the cutting edge of server monitoring technology. We're not
                  just experts in our field—we're driven by a shared vision to redefine server monitoring and deliver
                  solutions that empower businesses to thrive in the modern digital landscape.
                </p>
              </TeamItem>
              <TeamItem>
                <h2 className="section-title">JOIN US ON OUR JOURNEY</h2>
                <p className="section-subtitle">
                  Every innovation, breakthrough, and success story at ResourceDekho is powered by a team of dedicated,
                  passionate professionals who are committed to achieving excellence in everything we do. Our engineers,
                  data scientists, and support staff work tirelessly and collaboratively to ensure that we stay at the
                  cutting edge of server monitoring technology. We don’t just focus on technical expertise—we also
                  emphasize a shared vision to redefine the future of server monitoring.
                </p>
              </TeamItem>
            </TeamRow>
          </div>
        </Container>
      </SectionWhite>
    </>
  );
}

export default AboutUs;
