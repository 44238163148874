import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import SecondaryButton from './Assets/SecondaryButton';
import aboutUsImg from './Assets/about-us-img.jpg';
import './Assets/LandingRoute.css';

function AboutUs() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isMobile = window.innerWidth <= 768;
  const slideRightClass = isMobile ? '' : scrollPosition > 950 ? 'aboutSlide-right show' : 'aboutSlide-right';
  const slideLeftClass = isMobile ? '' : scrollPosition > 950 ? 'aboutSlide-left show' : 'aboutSlide-left';

  return (
    <Container
      id="about"
      disableGutters
      maxWidth="lg"
      sx={{
        px: {
          xs: 2,
          sm: 5,
          md: 3.5,
        },
        my: 5,
      }}
    >
      <Box sx={{ marginBottom: 0 }}>
        <h1>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: '600',
              fontSize: '26px',
              lineHeight: '48px',
              color: '#161414',
              textAlign: {
                xs: 'center',
              },
            }}
          >
            About Us
          </Typography>
        </h1>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          justifyContent: 'center',
          gap: 4,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flex: 1 }} className={slideLeftClass}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '32px',
              color: '#5B5B5B',
              my: 4,
              '@media(max-width: 600px)': {
                textAlign: 'justify',
              },
            }}
          >
            In today's digital landscape, the heartbeat of any organization lies in its server infrastructure. Our
            mission is to empower businesses of all sizes with cutting-edge monitoring solutions, ensuring uptime,
            performance, and security. Through our intuitive dashboard, we strive to give you actionable insights,
            enabling you to make informed decisions and drive growth.
          </Typography>
          <SecondaryButton text="Go to About Page" to="/about" />
        </Box>
        <Box sx={{ flex: 1 }} className={slideRightClass}>
          <img
            src={aboutUsImg}
            alt="About Us"
            title="Learn more about us"
            width={500}
            height={300}
            loading="lazy"
            style={{
              borderRadius: '24px',
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Box>
      </Box>
    </Container>
  );
}

export default AboutUs;
